body {
    margin: 0;
    font-family: 'Tangerine', serif;
    font-size: 35px;
}

h2, h6 {
    text-shadow: 4px 4px 4px #aaa;
}

.centerWithMargin {
    margin: 0 auto;
}

.container {
    height: 100vh;
    width: 100%;
    background-color: pink;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.particlesstyle {
    opacity: 0.1;
    z-index: 0;
}

.centered-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.leftAlignedRow {
    display: flex;
    flex-direction: row;
    justify-content: left;
}


.col {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.createEventContainer {
    display: flex;
    height: 400px;
    max-width: 500px;
    min-width: 300px;
    width: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* margin: 0 auto; */
    justify-content: center;
    flex-direction: column;
}

.createEventTitle {
    text-align: center;
    color: grey;
    text-shadow: 4px 4px 4px #aaa;
    margin-bottom: 2px;
}

.eventDetailsInputTable {
    margin: 0 auto;
}

.createButton {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 100px;
    text-align: center;
    margin-top: 25px;

}

.inputHelperText {
    font-size: 20;
}

.tableLabel {
    font-size: 30;
    color: grey;
    text-shadow: 4px 4px 4px #aaa;
}

input {
    border: 2px solid pink;
    border-radius: 4px;
    width: 200px;
    height: 20px;
}

input:focus {
    border: 2px solid grey;
    box-shadow: 0 0 5px grey;

}

.labelTd {
    width: 50px;
}

.height50 {
    height: 120;
}

.height200 {
    height: 200;
}

.inputImage {
    margin-left: 10px;
}

.hide {
    display: none;
}

#welcomeMessage {
    font-size: 60;
}

#loadingBanner {
    font-size: 60;
}

.width-50 {
    width: 50;
}

.width-300 {
    width: 300px;
}

.textAlign-center {
    text-align: center;
}

/* CSS */
.button-56 {
    margin-top: 10px;
    align-items: center;
    background-color: #fee6e3;
    border: 2px solid pink;
    border-radius: 8px;
    box-sizing: border-box;
    color: grey;
    cursor: pointer;
    display: flex;
    font-family: 'Tangerine', serif;
    font-size: 40px;

    text-shadow: 4px 4px 4px #aaa;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-56:after {
    background-color: #111;
    border-radius: 8px;
    content: "";
    display: block;
    height: 48px;
    left: 0;
    width: 100%;
    position: absolute;
    top: -2px;
    transform: translate(8px, 8px);
    transition: transform .2s ease-out;
    z-index: -1;
}

.button-56:hover:after {
    transform: translate(0, 0);
}

.button-56:active {
    background-color: #ffdeda;
    outline: 0;
}

.button-56:hover {
    outline: 0;
}

@media (min-width: 768px) {
    .button-56 {
        padding: 0 40px;
    }
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden;
    /* Hidden by default. Visible on click */
    min-width: 250px;
    /* Set a default minimum width */
    /* margin-left: -125px; */
    margin: auto;
    /* Divide value of min-width by 2 */
    background-color: #333;
    /* Black background color */
    color: #fff;
    /* White text color */
    text-align: center;
    /* Centered text */
    border-radius: 2px;
    /* Rounded borders */
    padding: 16px;
    /* Padding */
    position: fixed;
    /* Sit on top of the screen */
    z-index: 1;
    /* Add a z-index if needed */
    /* left: 25%; */
    /* Center the snackbar */
    bottom: 30px;
    /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}