body {
  margin: 0;
  font-family: Tangerine, serif;
  font-size: 35px;
}

h2, h6 {
  text-shadow: 4px 4px 4px #aaa;
}

.centerWithMargin {
  margin: 0 auto;
}

.container {
  height: 100vh;
  width: 100%;
  background-color: pink;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.particlesstyle {
  opacity: .1;
  z-index: 0;
}

.centered-row {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.leftAlignedRow {
  flex-direction: row;
  justify-content: left;
  display: flex;
}

.col {
  flex-direction: column;
  justify-content: start;
  display: flex;
}

.createEventContainer {
  height: 400px;
  max-width: 500px;
  min-width: 300px;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  display: flex;
  box-shadow: 0 3px 8px #0000003d;
}

.createEventTitle {
  text-align: center;
  color: gray;
  text-shadow: 4px 4px 4px #aaa;
  margin-bottom: 2px;
}

.eventDetailsInputTable {
  margin: 0 auto;
}

.createButton {
  width: 100px;
  text-align: center;
  margin-top: 25px;
  box-shadow: 0 54px 55px #00000040, 0 -12px 30px #0000001f, 0 4px 6px #0000001f, 0 12px 13px #0000002b, 0 -3px 5px #00000017;
}

.inputHelperText {
  font-size: 20px;
}

.tableLabel {
  color: gray;
  text-shadow: 4px 4px 4px #aaa;
  font-size: 30px;
}

input {
  width: 200px;
  height: 20px;
  border: 2px solid pink;
  border-radius: 4px;
}

input:focus {
  border: 2px solid gray;
  box-shadow: 0 0 5px gray;
}

.labelTd {
  width: 50px;
}

.height50 {
  height: 120px;
}

.height200 {
  height: 200px;
}

.inputImage {
  margin-left: 10px;
}

.hide {
  display: none;
}

#welcomeMessage, #loadingBanner {
  font-size: 60px;
}

.width-50 {
  width: 50px;
}

.width-300 {
  width: 300px;
}

.textAlign-center {
  text-align: center;
}

.button-56 {
  box-sizing: border-box;
  color: gray;
  cursor: pointer;
  text-shadow: 4px 4px 4px #aaa;
  height: 48px;
  max-width: 100%;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-color: #fee6e3;
  border: 2px solid pink;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 0 25px;
  font-family: Tangerine, serif;
  font-size: 40px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button-56:after {
  content: "";
  height: 48px;
  width: 100%;
  z-index: -1;
  background-color: #111;
  border-radius: 8px;
  transition: transform .2s ease-out;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
  transform: translate(8px, 8px);
}

.button-56:hover:after {
  transform: translate(0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #333;
  border-radius: 2px;
  margin: auto;
  padding: 16px;
  position: fixed;
  bottom: 30px;
}

#snackbar.show {
  visibility: visible;
  animation: .5s fadein, .5s 2.5s fadeout;
}

@keyframes fadein {
  from {
    opacity: 0;
    bottom: 0;
  }

  to {
    opacity: 1;
    bottom: 30px;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    bottom: 30px;
  }

  to {
    opacity: 0;
    bottom: 0;
  }
}

.tooltip {
  cursor: pointer;
  border-bottom: 1px dotted #000;
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #000;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/*# sourceMappingURL=index.09f7c655.css.map */
